import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';

export const columns: QueryTableColumnProps = [
  {
    title: '题库名称',
    key: 'questionLibraryName',
    dataIndex: 'questionLibraryName',
    width: 540,
  },
  {
    title: '题目数',
    key: 'questionCount',
    width: 120,
    dataIndex: 'questionCount',
  },
  {
    title: '所有者',
    key: 'owners',
    width: 120,
    dataIndex: 'owners',
    render: (owners: { userId: string; username: string }[]) => {
      return <div>{owners?.map((item) => item.username).join('、')}</div>;
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  questionLibraryName: {
    type: 'string',
    title: '题库名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入题库名称',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};

export const createSchema: QueryTableFilterISchemaProperties = {
  questionLibraryName: {
    type: 'string',
    title: '题库名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入题库名称',
    },
    'x-validator': [{ required: true, message: '请输入题库名称' }],
  },
};

export const shareSchema: QueryTableFilterISchemaProperties = {
  userId: {
    type: 'number',
    title: '分享人',
    'x-component': 'UserSearch',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入分享人用户名',
    },
    'x-validator': [{ required: true, message: '请输入分享人用户名' }],
  },
};
