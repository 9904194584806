import { ArrayField } from '@formily/core';
import { useField, Field, observer } from '@formily/react';
import { Input } from '@formily/antd-v5';
import { useEffect } from 'react';

interface IProps {
  multiple?: boolean;
}

/** value: string[] */
export const TextAnswer = observer((props: IProps) => {
  const { multiple } = props;
  const field = useField<ArrayField>();

  useEffect(() => {
    if (!field.value?.length) {
      field.push('');
    }
  }, [field]);

  return !multiple ? (
    <Field
      name={0}
      component={[
        Input.TextArea,
        {
          placeholder: '请输入答案',
          style: { width: '100%' },
        },
      ]}
      validator={[{ required: true, message: '请输入答案' }]}></Field>
  ) : (
    field.value?.map((_item, index) => {
      return (
        <Field
          name={index}
          key={index}
          value={_item.value || ''}
          component={[
            Input,
            {
              placeholder: '请输入答案',
              style: { width: '100%', marginBottom: 12 },
            },
          ]}
          validator={[{ required: true, message: '请输入答案' }]}></Field>
      );
    })
  );
});
