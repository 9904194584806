import request from 'src/api/http';
import type {
  IGetMenuListReq,
  IMenuItem,
  IGetUserListReq,
  ICreateUserReq,
  IUpdateUserReq,
  IUserItem,
  // IDeptItem,
  IGetDeptListReq,
  ICreateDeptReq,
  IUpdateDeptReq,
  IGetRoleListReq,
  IRoleItem,
  ICreateRoleReq,
  IGetPostListReq,
  IPostItem,
  ICreatePostReq,
  IUpdatePostReq,
  IUpdateRoleReq,
  IUpdateRoleDataScopeReq,
  IGetDictListReq,
  IDictItem,
  IGetDictDataListReq,
  IDictDataItem,
  IGetNoticeListReq,
  INoticeItem,
  IGetLogsLoginListReq,
  ILoginLogItem,
  IGetLogsOperListReq,
  IOperLogItem,
  INoticeHistoryItem,
  IGetNoticeHistoryListReq,
  UpdatePersonal,
  IGetFilesListReq,
  IFilesInfo,
} from 'src/api/types/system';
import type { IPageInfo, IResultList } from 'src/api/types/common';

/* -------菜单------- */
// 获取菜单列表
export const getMenuList = (params: IGetMenuListReq) => {
  return request.get<IMenuItem[]>('/menu/list', {
    params,
  });
};

// 新增菜单
export const createMenu = (data: IMenuItem) => {
  return request.post('/menu/create', {
    data,
  });
};

// 更新菜单
export const updateMenu = (data: IMenuItem) => {
  return request.put<boolean>('/menu/update', {
    data,
  });
};

// 删除菜单
export const deleteMenu = (menuId: number) => {
  return request.delete<boolean>(`/menu/delete`, { data: { ids: [menuId] } });
};

/* -------用户------- */
// 获取人员列表
export const getUserList = (params: IGetUserListReq) => {
  return request.get<IResultList<IUserItem>>('/user/list', {
    params,
  });
};

export const getUserListByRoleId = (params: { roleId: number } & IPageInfo) => {
  return request.get<IResultList<IUserItem>>('/user/listByRoleId/', {
    params,
  });
};

export const unBindRoleByUserId = (data: { userId: number; roleId: number }) => {
  return request.post('/user/unBindRoleByUserId', { data });
};

// 新增人员
export const createUser = (data: ICreateUserReq) => {
  return request.post('/user/create', {
    data,
  });
};

// 更新人员
export const updateUser = (data: IUpdateUserReq) => {
  return request.post<boolean>('/user/update', {
    data,
  });
};

// 删除人员
export const deleteUser = (ids: number[]) => {
  return request.post<boolean>(`/user/remove`, { data: { ids } });
};

// 修改个人信息
export const updatePersonal = (data: UpdatePersonal) => {
  return request.post<boolean>('/user/personal/update', {
    data,
  });
};

// 修改头像
export const updateAvatar = (data: { avatar: string }) => {
  return request.post<boolean>('/user/personal/avatar', {
    data,
  });
};

// 修改密码
export const updatePassword = (data: { oldPassword: string; newPassword: string }) => {
  return request.put<boolean>('/user/personal/password', {
    data,
  });
};

/* -------部门------- */
// 获取部门列表
export const getDeptList = (params: IGetDeptListReq) => {
  return request.get('/dept/list', {
    params,
  });
};

// 新增部门
export const createDept = (data: ICreateDeptReq) => {
  return request.post('/dept/create', { data });
};

// 更新部门
export const updateDept = (data: IUpdateDeptReq) => {
  return request.put('/dept/update', { data });
};

// 删除部门
export const deleteDept = (ids: number[]) => {
  return request.delete('/dept/delete', { data: { ids } });
};

/* -------角色------- */
// 获取角色列表
export const getRoleList = (params: IGetRoleListReq) => {
  return request.get<IResultList<IRoleItem>>('/role/list', { params });
};

// 新增角色
export const createRole = (data: ICreateRoleReq) => {
  return request.post('/role/create', { data });
};

// 获取角色详情
export const getRoleDetail = (roleId: number) => {
  return request.get<IRoleItem & { deptIds: number[] }>(`/role/detail`, { params: { roleId } });
};

// 更新角色
export const updateRole = (data: IUpdateRoleReq) => {
  return request.put('/role/update', { data });
};

// 删除角色
export const deleteRole = (ids: number[]) => {
  return request.delete('/role/delete', { data: { ids } });
};

// 获取角色路由
export const getRoleToMenu = (id: number) => {
  return request.get(`/menu/tree/${id}`);
};

// 设置角色路由
export const setMenuByRole = (data: { roleId: number; menuIds: number[] }) => {
  return request.post('/role/bindMenu', { data });
};

// 修改角色权限
export const setDataScope = (data: IUpdateRoleDataScopeReq) => {
  return request.post('/role/datascope', { data });
};

/* -------岗位------- */
// 获取岗位列表
export const getPostList = (params: IGetPostListReq) => {
  return request.get<IResultList<IPostItem>>('/post/list', { params });
};

// 新增岗位
export const createPost = (data: ICreatePostReq) => {
  return request.post('/post/create', { data });
};

// 更新岗位
export const updatePost = (data: IUpdatePostReq) => {
  return request.put('/post/update', { data });
};

// 删除岗位
export const deletePost = (ids: number[]) => {
  return request.delete('/post/delete', { data: { ids } });
};

/* -------字典------- */

// 获取字典列表
export const getDictList = (params: IGetDictListReq) => {
  return request.get<IResultList<IDictItem>>(`/dict/list`, { params });
};

// 创建字典
export const createDict = (data: IDictItem) => {
  return request.post('/dict/create', { data });
};

// 更新字典
export const updateDict = (data: IDictItem) => {
  return request.put('/dict/update', { data });
};

// 删除字典
export const deleteDict = (ids: number[]) => {
  return request.delete('/dict/delete', { data: { ids } });
};

// 获取字典数据列表
export const getDictDataList = (params: IGetDictDataListReq) => {
  return request.get<IResultList<IDictDataItem>>('/dict/data/list/', { params });
};

// 创建字典数据
export const createDictData = (data: IDictDataItem) => {
  return request.post('/dict/data/create', { data });
};

// 更新字典数据
export const updateDictData = (data: IDictDataItem) => {
  return request.put('/dict/data/update', { data });
};

// 删除字典数据
export const deleteDictData = (ids: number[]) => {
  return request.delete('/dict/data/delete', { data: { ids } });
};

// 刷新字典
export const refreshDict = () => {
  return request.post('/dict/refresh');
};

// 通知公告信息
export const getNoticeList = (params: IGetNoticeListReq) => {
  return request.get<IResultList<INoticeItem>>('/notice/list', { params });
};

// 创建通知公告
export const createNotice = (data: INoticeItem) => {
  return request.post('/notice/create', { data });
};

// 更新通知公告
export const updateNotice = (data: INoticeItem) => {
  return request.put('/notice/update', { data });
};

// 删除通知公告
export const deleteNotice = (ids: number[]) => {
  return request.delete('/notice/delete', { data: { ids } });
};

// 推送操作日志
export const pushNotice = (data: { id: number; content: string }) => {
  return request.post('/notice/push', { data });
};

// 获取公告历史
export const getNoticeHistory = (params?: IGetNoticeHistoryListReq) => {
  return request.get<INoticeHistoryItem[]>('/notice/history', { params: params ?? {} });
};

// 标记已读
export const markRead = (ids: number[]) => {
  return request.put('/notice/read', { data: { ids } });
};

// 获取登录日志列表
export const getLoginLogList = (params: IGetLogsLoginListReq) => {
  return request.get<IResultList<ILoginLogItem>>('/logs/login/list', { params });
};

// 删除登录日志
export const deleteLoginLog = (ids: string[]) => {
  return request.delete('/logs/login/delete', { data: { ids } });
};

// 获取操作日志列表
export const getOperLogList = (params: IGetLogsOperListReq) => {
  return request.get<IResultList<IOperLogItem>>('/logs/oper/list', { params });
};

// 删除操作日志
export const deleteOperLog = (ids: string[]) => {
  return request.delete('/logs/oper/delete', { data: { ids } });
};

/* -------文件------- */
export const getFilesList = (params: IGetFilesListReq) => {
  return request.get<IResultList<IFilesInfo>>('/files/list', { params });
};

export const deleteFiles = (ids: string[]) => {
  return request.delete('/files/delete', { data: { ids } });
};

export const createFiles = (data: IFilesInfo) => {
  return request.post('/files/create', { data });
};
