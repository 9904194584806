import { useRef } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Form, FormRef } from 'src/components/Form';
import { shareQuestionLibrary } from 'src/api/clients/exam';
import { shareSchema } from './schema';
import { IQuestionLibraryItem } from 'src/api/types/exam';
// import { Input } from '@formily/antd-v5';
import { UserSearch } from 'src/components/formily';

interface IProps extends ModalProps {
  data: IQuestionLibraryItem | null;
}

interface IFormData {
  userId: number;
}

export function QuestionLibraryShare(props: IProps) {
  const { open, onCancel, data, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);

  const onCancelHandle = useMemoizedFn((e) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    const { questionLibraryId } = data || {};
    if (!questionLibraryId) {
      message.error('题库ID错误');
      return;
    }
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<IFormData>();
    setTrue();
    try {
      await shareQuestionLibrary({
        questionLibraryId: questionLibraryId,
        userId: values?.userId,
      });
      message.success('分享成功');
      onCancelHandle(e);
    } finally {
      setFalse();
    }
  });

  return (
    <Modal
      open={open}
      title="分享题库"
      onOk={onSubmit}
      width={500}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      {...extra}>
      <Form
        className="py-4"
        ref={formRef}
        components={{ UserSearch }}
        schema={shareSchema}
        hideAction
        layout={{ labelCol: 5, wrapperCol: 19 }}
      />
    </Modal>
  );
}
