import { QuestionType } from 'src/api/types/exam';

export const questionTypeMap = {
  [QuestionType.SingleChoice]: '单选题',
  [QuestionType.MultipleChoice]: '多选题',
  [QuestionType.QuestionAndAnswer]: '问答题',
  [QuestionType.Judgment]: '判断题',
  [QuestionType.UnCertainMultiple]: '不定项选择',
  [QuestionType.FillInBlanks]: '填空题',
};

export const questionTypeList = [
  {
    label: questionTypeMap[QuestionType.SingleChoice],
    value: QuestionType.SingleChoice,
  },
  {
    label: questionTypeMap[QuestionType.MultipleChoice],
    value: QuestionType.MultipleChoice,
  },
  {
    label: questionTypeMap[QuestionType.QuestionAndAnswer],
    value: QuestionType.QuestionAndAnswer,
  },
  {
    label: questionTypeMap[QuestionType.Judgment],
    value: QuestionType.Judgment,
  },
  {
    label: questionTypeMap[QuestionType.UnCertainMultiple],
    value: QuestionType.UnCertainMultiple,
  },
  {
    label: questionTypeMap[QuestionType.FillInBlanks],
    value: QuestionType.FillInBlanks,
  },
];
