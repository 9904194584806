import { QuestionType } from 'src/api/types/exam';
import { ISchema } from '@formily/react';
import { guid } from 'src/utils/tools';

export const QuestionTypeOptions = [
  { label: '单选题', value: QuestionType.SingleChoice },
  { label: '多选题', value: QuestionType.MultipleChoice },
  { label: '简答题', value: QuestionType.QuestionAndAnswer },
  { label: '判断题', value: QuestionType.Judgment },
  { label: '不定项选择题', value: QuestionType.UnCertainMultiple },
  { label: '填空题', value: QuestionType.FillInBlanks },
];

export const schema: (isScore?: boolean) => ISchema = (isScore) => ({
  type: 'object',
  properties: {
    questionType: {
      type: 'number',
      title: '题型',
      'x-component': 'Select',
      'x-component-props': {
        placeholder: '请选择题型',
        options: QuestionTypeOptions,
      },
      default: QuestionType.SingleChoice,
      'x-decorator': 'FormItem',
      'x-validator': [{ required: true, message: '请选择题型' }],
    },
    container: {
      type: 'void',
      'x-component': 'Container',
      'x-reactions': {
        dependencies: ['.questionType'],
        fulfill: {
          state: {
            visible: '{{$deps[0] > 0}}',
          },
        },
      },
      properties: {
        body: {
          type: 'string',
          title: '题目',
          'x-component': 'EditorWrapper',
          'x-component-props': {
            placeholder: '请输入题目',
            isSimple: true,
          },
          'x-decorator': 'FormItem',
          'x-validator': [{ required: true, message: '请输入题目' }],
          'x-reactions': {
            dependencies: ['.questionType'],
            when: '{{$deps[0] === 6}}',
            fulfill: {
              schema: {
                'x-decorator-props': {
                  extra: '输入【】按钮在题目中标记需填空处',
                  tooltip:
                    '例子：目前中国有【1】个省级行政区，包括【2】个省、 【3】个自治区、 【4】个直辖市、 【5】个特别行政区。',
                },
              },
            },
            otherwise: {
              schema: {
                'x-decorator-props': {
                  extra: '',
                  tooltip: null,
                },
              },
            },
          },
        },
        options: {
          type: 'array',
          title: '选项及正确答案',
          'x-component': 'Choice',
          'x-decorator': 'FormItem',
          default: new Array(4).fill({
            value: '',
            isAnswer: false,
            id: guid(),
          }),
          'x-decorator-props': {
            extra: (
              <span style={{ fontSize: 14, color: '#c1c1c1' }}>选中左侧按钮，标注正确答案</span>
            ),
            asterisk: true,
          },
          'x-validator': [{ required: true, message: '请输入选项' }],
          'x-reactions': {
            dependencies: ['.questionType'],
            when: '{{$deps[0] !== 6 && $deps[0] !== 3}}',
            fulfill: {
              schema: {
                'x-display': 'visible',
              },
            },
            otherwise: {
              schema: {
                'x-display': 'none',
              },
            },
          },
        },
        textAnswer: {
          type: 'array',
          title: '正确答案',
          'x-decorator': 'FormItem',
          'x-component': 'TextAnswer',
          'x-display': 'none',
          'x-component-props': {
            placeholder: '请输入正确答案',
          },
          default: [''],
          'x-validator': [{ required: true, message: '请输入正确答案' }],
          'x-reactions': {
            dependencies: ['.questionType'],
            when: '{{$deps[0] === 6 || $deps[0] === 3}}',
            fulfill: {
              schema: {
                'x-display': 'visible',
              },
            },
            otherwise: {
              schema: {
                'x-display': 'none',
              },
            },
          },
        },
        score: {
          type: 'number',
          title: '分值',
          'x-display': isScore ? 'visible' : 'none',
          'x-component': 'NumberPicker',
          'x-component-props': {
            placeholder: '请输入分值',
            min: 0,
          },
          'x-decorator': 'FormItem',
          'x-validator': [{ required: true, message: '请输入分值' }],
        },
        solution: {
          type: 'string',
          title: '解析',
          'x-decorator': 'FormItem',
          'x-component': 'Input.TextArea',
          'x-component-props': {
            placeholder: '请输入解析',
            style: {
              resize: 'none',
            },
            rows: 5,
          },
        },
      },
    },
  },
});
