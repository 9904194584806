import Compressor from 'compressorjs';
import axios from 'axios';
import { IMenuDataItem, RouteObject, VisibleEnum } from 'src/interface';

export const generateMenuTree = (menuArray: IMenuDataItem[], parentId = 0) => {
  const menuTree = [] as IMenuDataItem[];

  menuArray.forEach((menuItem) => {
    if (menuItem.parentId === parentId && menuItem.visible === VisibleEnum.SHOW) {
      const children = generateMenuTree(menuArray, menuItem.menuId);
      if (children.length) {
        menuItem.children = children;
      }
      menuTree.push(menuItem);
    }
  });

  return menuTree;
};

export const flatRoutes = (routeList: RouteObject[]): RouteObject[] => {
  const newRoutesList = [] as RouteObject[];
  for (const menu of routeList) {
    if (!menu.children) {
      newRoutesList.push(menu);
    } else {
      menu.children.forEach((item) => {
        newRoutesList.push(item);
      });
    }
  }
  return newRoutesList;
};

export function numberGrow(ele: HTMLElement, num: number, start = 0, duration = 1000) {
  if (!ele) return;
  // 已加减号开头，存在多个数字 可能存在点后多个数字
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(String(num))) {
    ele.innerHTML = `${num}`;
    return;
  }
  let startNum = 0;
  let timer: null | NodeJS.Timeout;
  const step = Math.floor((num * 20) / duration) ? Math.floor((num * 20) / duration) : num;

  timer = setInterval(() => {
    startNum = start + step;
    if (startNum >= num) {
      if (timer) {
        clearInterval(timer);
      }
      startNum = num;
      timer = null;
    } else {
      start = startNum;
    }
    ele.innerHTML = String(startNum);
  }, 20);
}

export function routerToMenuList(routeList: RouteObject[], parentPath?: string): IMenuDataItem[] {
  if (!routeList) return [];
  const path = parentPath ?? '';
  const menuList = [] as IMenuDataItem[];
  for (const route of routeList) {
    let menu = null as IMenuDataItem[] | null;
    if (route.children && route.children.length > 0) {
      menu = routerToMenuList(route.children, route.path);
    }
    const menuItem = {
      path: route.path?.startsWith('/') ? path + route.path : path + '/' + route.path,
      name: route.name,
      locale: route?.data?.locale,
      icon: route?.icon,
    } as IMenuDataItem;
    if (menu) menuItem.children = menu;
    menuList.push(menuItem);
  }
  return menuList;
}

export function stripHtmlTags(str: string): string {
  if (!str) return '';
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

export async function imageUrlToFile(url: string, fileName = new Date().getTime() + '.png') {
  try {
    // 第一步：使用axios获取网络图片数据
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    const imageData = response.data;

    // 第二步：将图片数据转换为Blob对象
    const blob = new Blob([imageData], {
      type: response.headers['content-type'],
    });

    // 第三步：创建一个新的File对象
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error('将图片转换为File对象时发生错误:', error);
    return null;
  }
}

export const compress = (file: File) =>
  new Promise<Blob>((resolve, reject) => {
    const limitS = file.size / 1024 / 1024;

    let quality = 0.6;

    if (limitS >= 20) {
      quality = 0.3;
    }
    if (limitS >= 10) {
      quality = 0.4;
    }

    new Compressor(file, {
      quality,
      success: resolve,
      error: reject,
    });
  });

export function formatBytes(bytes: number) {
  if (bytes === 0) return '0 Bytes';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = bytes / Math.pow(1024, i);
  return `${value.toFixed(2)} ${sizes[i]}`;
}

export const queryStringToObject = (queryString: string): Record<string, string> => {
  const params: Record<string, string> = {};

  // 移除 '?' 开头的部分（如果有）
  const query = queryString.startsWith('?') ? queryString.slice(1) : queryString;

  // 以 '&' 为分隔符分割查询字符串
  const pairs = query.split('&');

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    if (key) {
      // 解码并存储键值对
      params[decodeURIComponent(key)] = value ? decodeURIComponent(value) : '';
    }
  });
  return params;
};

export function JsonToQuery(obj: any): string {
  let str = '';
  if (!obj) {
    return str;
  }
  Object.keys(obj).forEach((key) => {
    if (str) {
      str += '&';
    }
    str += `${key}=${String(obj[key])}`;
  });

  return str;
}

/**
 *
 * @description 千位分隔符 ,将数字分组表示, 去除掉小数点后数字 如 '1234567.00' -> '1,234,567'
 * @param {number|string} s 将分组的字符串
 * @param {number} n n个字符分为一组, 默认是3个
 * @param {string} splitChar 分隔字符，默认是','
 */
export function numberGroupWithoutFloat(s = '0', n = 3, splitChar = ',') {
  s = s + '';

  if (!/^([+-])?(\d+)(\.\d+)?$/.test(s)) {
    return s;
  }
  const a = RegExp.$1;
  let b = RegExp.$2;
  const reg = new RegExp('(\\d+)(\\d{' + n + '})(' + splitChar + '|$)');

  while (reg.test(b)) {
    b = b.replace(reg, '$1' + splitChar + '$2$3');
  }
  return a + b;
}

/**
 * 数字处理-百分比
 */
export function numberPercent(
  value: number | string,
  precision = 2,
  surfix = '%',
  prefix?: boolean,
) {
  // 带正负号
  const sign = Number(value) >= 0 ? '+' : '-';
  const num = (Number(value) * 100).toFixed(precision);
  return prefix ? sign + num + surfix : num + surfix;
}

/**
 * 数字处理-百分比
 * 不需要乘100，直接加百分号
 */
export function numberPercentWithoutOperation(
  value: number | string,
  precision = 2,
  surfix = '%',
  prefix?: boolean,
) {
  // 带正负号
  const sign = Number(value) >= 0 ? '+' : '-';
  const num = Number(value).toFixed(precision);
  return prefix ? sign + num + surfix : num + surfix;
}

export function formatString2Array(values: string | undefined | string[]): string[] {
  return values == null ? [] : Array.isArray(values) ? values : [values];
}

/**
 * 格式化字符串
 * 输入 =》5.42% 输出 =》上升5.42%
 * 输入 =》-2.1% 输出 =》下降2.1%
 * 输入 =》'-' 输出 =》'-'
 */
export function format_rate_str(rate: string | number, type = 1): string {
  rate = String(rate);
  if (rate === '-') return rate;
  const reg = /^-/gi;
  if (reg.test(rate)) {
    return `${type == 1 ? '下降' : '低于'}${rate.replace(reg, '')}`;
  }
  return `${type == 1 ? '上升' : '高于'}${rate}`;
}

/**
 * 获取浏览器缩放比
 */
export function getWindowRatio(): number {
  if (window.innerWidth && window.outerWidth) {
    return window.outerWidth / window.innerWidth;
  }
  return 1;
}
/**
 * 生成4位随机数
 *
 * @inner
 * @return
 */
export function s4(): string {
  /* tslint:disable: no-bitwise*/
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  /* tslint:enable: no-bitwise*/
}

/**
 * 生成全局唯一标识符
 *
 * @return 返回一个guid
 */
export function guid(): string {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

/** 生成指定长度空数组 */
export function emptyArray(num: number): any[] {
  return new Array(num).join(',').split(',');
}
/** number => letter */
export function numberToLetter(number: number) {
  const letter = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
  if (number > letter.length) {
    let letterStr = '';
    let count = 0;
    let num = number;
    while (num > letter.length) {
      const n = Math.floor(num / letter.length);
      if (n > letter.length) {
        letterStr += 'A';
      }
      num = n;
      if (count == 0) count = n;
    }
    if (number - count * letter.length === 0) {
      return letter[num - 2] + letter[letter.length - 1];
    }
    return (letterStr += letter[num - 1] + letter[number - count * letter.length - 1]);
  }
  return letter[number - 1];
}
