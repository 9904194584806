import request from 'src/api/http';
import {
  IQueryQuestion,
  IQueryQuestionLibrary,
  IQuestionItem,
  IQuestionLibraryItem,
} from '../types/exam';
import { IResultList } from '../types/common';

export const getQuestionLibraryList = (params: IQueryQuestionLibrary) => {
  return request.get<IResultList<IQuestionLibraryItem>>('/exam/question_library/list', { params });
};

export const deleteQuestionLibrary = (ids: string[]) => {
  return request.delete(`/exam/question_library/delete`, { data: { ids } });
};

export const createQuestionLibrary = (data: { questionLibraryName: string }) => {
  return request.post<boolean>('/exam/question_library/create', { data });
};

export const updateQuestionLibrary = (data: {
  questionLibraryName: string;
  questionLibraryId: string;
}) => {
  return request.put<boolean>('/exam/question_library/update', { data });
};

export const shareQuestionLibrary = (data: { questionLibraryId?: string; userId?: number }) => {
  return request.post<boolean>('/exam/question_library/share', { data });
};

export const getQuestionLibraryDetail = (questionLibraryId: string) => {
  return request.get<IQuestionLibraryItem>(`/exam/question_library/detail`, {
    params: { questionLibraryId },
  });
};

export const getQuestionList = (params: IQueryQuestion) => {
  return request.get<IResultList<IQuestionLibraryItem>>('/exam/question/list', { params });
};

export const createQuestion = (data: IQuestionItem) => {
  return request.post<boolean>('/exam/question/create', { data });
};

export const deleteQuestion = (ids: string[]) => {
  return request.delete(`/exam/question/delete`, { data: { ids } });
};
