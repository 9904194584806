import type { CancelTokenSource } from 'axios';
import request from 'src/api/http';
import {
  IDistrictItem,
  IGetDistrictsParams,
  IUploadMergeResp,
  IUploadVerifyFile,
} from '../types/tools';

export const getDistrictList = (params?: IGetDistrictsParams) => {
  return request.get<IDistrictItem[]>('/amap/getLocation', { params });
};

export const uploadFile = (data: FormData) => {
  return request.post<string>('/upload/upload', {
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createUploadFile = (data: {
  filename: string;
  chunkFileName: string;
  chunk: Blob;
  setUploadProgress: (value: number) => void;
  cancelToken: CancelTokenSource;
  start: number;
  totalSize: number;
}) => {
  const { filename, chunkFileName, chunk, setUploadProgress, cancelToken, start, totalSize } = data;
  return request.post<boolean>(`/upload/${filename}`, {
    data: chunk,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    params: {
      chunkFileName,
      start,
    },
    onUploadProgress: (progressEvent) => {
      //上传进度发生变化的事件回调函数
      //progressEvent.loaded本次上传成功的字节+start上次上传成功的字节 /总字节数
      const percentCompleted = Math.round(((progressEvent.loaded + start) * 100) / totalSize);
      setUploadProgress(percentCompleted);
    },
    cancelToken: cancelToken.token,
  });
};

export const verifyFile = (filename: string) => {
  return request.get<IUploadVerifyFile>(`/upload/verify/${filename}`);
};

export const mergeFile = (filename: string): Promise<IUploadMergeResp> => {
  return request.get<IUploadMergeResp>(`/upload/merge/${filename}`);
};
