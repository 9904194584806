import { useBoolean, useMemoizedFn } from 'ahooks';
import { Upload as BaseUpload, message } from 'antd';
import type { UploadProps, UploadFile } from 'antd';
import { useEffect, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { CropModal } from './cropper';
import { isFunction } from 'lodash-es';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { Preview } from './preview';
import { compress } from 'src/utils/tools';
import { uploadFile } from 'src/api/clients/tools';

interface IProps extends Omit<UploadProps, 'onChange'> {
  fileSize?: number;
  // 是否支持预览
  isPreview?: boolean;
  // 是否支持删除
  isDelete?: boolean;
  // 是否支持上传中删除
  isUploadingDel?: boolean;
  // 是否支持剪裁
  isCrop?: boolean;
  // 是否自定义上传
  isOriginalFile?: boolean;
  // 初始化时过滤文件列表
  initFilter?: (fileList: UploadFile[]) => UploadFile[];
  // 剪裁回调
  onCrop?: (file: File | null, uploadFile: UploadFile) => Promise<void>;
  // 上传完成回调
  onChange?: (fileList: UploadFile[]) => void | Promise<void>;
  // 执行delete操纵
  onDelete?: (file: UploadFile) => Promise<void> | void;
}

const filterFile = (fileList: UploadFile[]) => {
  return fileList.filter((item) => {
    return item.status === 'done';
  });
};

export function Upload(props: IProps) {
  const {
    isOriginalFile,
    isCrop,
    isDelete = true,
    isPreview = true,
    fileList,
    maxCount,
    fileSize = 10 * 1024 * 1024,
    children,
    multiple,
    onCrop,
    onChange,
    onDelete,
    initFilter,
    customRequest,
    ...rest
  } = props;
  const [files, setFiles] = useState<UploadFile[]>(
    initFilter ? initFilter(fileList || []) : fileList || [],
  );
  const [visibleCrop, { setTrue: showCrop, setFalse: hideCrop }] = useBoolean(false);

  const [loading, { setTrue: setLoading, setFalse: setUnLoading }] = useBoolean(false);

  const [currentFile, setCurrentFile] = useState<UploadFile | null>(null);
  const handleChange = useMemoizedFn((info: { fileList: UploadFile[] }) => {
    const fileList = info.fileList || [];
    if (isOriginalFile) {
      setFiles(info.fileList);
      onChange?.(info.fileList);
      return;
    }
    const newFile = fileList
      .slice(0, maxCount)
      .filter((item) => {
        const error =
          item.status === 'error' || (item.status === 'done' && !item.response?.url && !item.url);
        if (error) {
          message.error('上传失败');
        }

        return item.status !== 'error';
      })
      .map((item) => ({ ...item, url: item.url || item.response?.url }));
    setFiles(newFile);
    if (isFunction(onChange) && newFile.every((item) => item.status === 'done')) {
      onChange(newFile);
    }
  });

  const onCropHandler = useMemoizedFn(async (file: UploadFile) => {
    setCurrentFile(file);
    showCrop();
  });

  const onBeforeUpload = async (file: UploadFile) => {
    if (maxCount && files.length >= maxCount) {
      message.error(`最多上传${maxCount}个文件`);
      return false;
    }
    if (fileSize && (file?.size || 0) > fileSize * 1024) {
      message.error(`文件大小不能超过${fileSize}KB`);
      return false;
    }
    // 支持剪裁 且 非批量上传
    if (isCrop && !multiple) {
      await onCropHandler(file);
      return false;
    }
    return true;
  };

  const handleCustomRequest = async (options: any) => {
    if (customRequest) return customRequest(options);
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    try {
      const newFile = await compress(file);
      formData.append('file', newFile);
      const result = await uploadFile(formData);
      if (!result) {
        onError('上传失败');
        return;
      }
      onSuccess({ url: result }, file);
    } catch (err) {
      onError(err);
    }
  };

  const handleDel = (file: UploadFile) => {
    const index = files.findIndex((item) => item.uid === file.uid);
    if (index < 0) {
      return;
    }
    setFiles((prev) => {
      prev.splice(index, 1);
      if (isFunction(onChange)) {
        onChange?.(files);
      }
      return [...prev];
    });
    if (isFunction(onDelete)) {
      onDelete(file);
    }
  };

  // 剪裁上传
  async function handleCropUpload(f: File | null, file: UploadFile) {
    if (!f) return;
    setLoading();
    try {
      const formData = new FormData();
      formData.append('file', f as File);
      const result = await uploadFile(formData);
      if (!result) {
        message.error('剪裁失败');
        return;
      }
      const newFiles = files.map((item) => {
        if (item.uid === file?.uid) {
          return {
            ...item,
            url: result,
            thumbUrl: result,
            response: { url: result },
            status: 'done' as UploadFileStatus,
          };
        }
        return item;
      });
      setFiles(newFiles);
      onChange?.(newFiles);
      hideCrop();
    } catch {
      message.error('剪裁失败');
      return;
    } finally {
      setUnLoading();
    }
  }
  useEffect(() => {
    setFiles(initFilter ? initFilter(fileList || []) : filterFile(fileList || []));
  }, [fileList, initFilter]);

  return (
    <>
      <BaseUpload
        accept="image/*"
        fileList={files}
        {...rest}
        multiple={multiple}
        onChange={handleChange}
        beforeUpload={onBeforeUpload}
        customRequest={handleCustomRequest}
        itemRender={(_, file: UploadFile) => (
          <Preview
            file={file}
            isPreview={isPreview}
            isDelete={isDelete}
            isCrop={isCrop && multiple}
            onRemove={handleDel}
            onCrop={onCropHandler}
          />
        )}>
        {!maxCount ? children : files?.length < maxCount ? children : null}
      </BaseUpload>
      <CropModal
        confirmLoading={loading}
        open={visibleCrop}
        onCancel={() => {
          hideCrop();
          setCurrentFile(null);
        }}
        file={currentFile}
        onChange={onCrop ? onCrop : handleCropUpload}></CropModal>
    </>
  );
}
