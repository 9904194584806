import { IPageInfo } from './common';

// 题目类型
export enum QuestionType {
  SingleChoice = 1, // 单选
  MultipleChoice, // 多选
  QuestionAndAnswer, // 问答
  Judgment, // 判断
  UnCertainMultiple, // 不定项选择
  FillInBlanks, // 填空
}

export interface IQueryQuestionLibrary extends IPageInfo {
  questionLibraryName?: string;
}

export interface IQueryQuestion extends IPageInfo {
  questionLibraryId?: string;
  questionType?: QuestionType;
}

export interface IQuestionLibraryItem {
  questionLibraryId: string;
  questionLibraryName: string;
  owners?: { userId: number; userName: string }[];
  questionCount?: number;
  createBy?: string;
  updateBy?: string;
  createTime?: string;
  updateTime?: string;
}

export interface IQuestionBase {
  questionType: QuestionType;
  body: string;
  options?: string[];
  answer?: number[];
  solution?: string;
  textAnswer?: string[];
}

export interface IQuestionItem extends IQuestionBase {
  questionId?: string;
  questionLibraryId?: string;
}
