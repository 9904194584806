import React, { useRef } from 'react';
import { Modal, Spin } from 'antd';
import type { ModalProps } from 'antd';
import { useBoolean } from 'ahooks';
import { uploadFile } from 'src/api/clients/tools';

interface VideoUploadModalProps extends ModalProps {
  onUpload: (url: string) => void;
}

export function UploadVideoModal(props: VideoUploadModalProps) {
  const { onCancel, open, onUpload, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = async (file: File) => {
    setTrue();
    try {
      const fData = new FormData();
      fData.append('file', file);
      const res = await uploadFile(fData);
      console.log(res);
      onUpload(res);
    } finally {
      setFalse();
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('video/')) {
      handleUpload(file);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleUpload(file);
    }
  };

  return (
    <Modal title="上传视频" open={open} onCancel={onCancel} footer={null} {...extra}>
      <Spin spinning={loading} tip="上传中...">
        <div
          className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center my-8"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current?.click()}>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="video/*"
            onChange={handleFileChange}
          />
          <div className="text-gray-500 cursor-pointer">
            <p className="mb-2">拖拽视频文件到这里，或点击上传</p>
            <p className="text-sm">支持 MP4, WebM 等格式</p>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}
