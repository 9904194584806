import {} from 'react';
import { connect, mapProps } from '@formily/react';

interface IProps {
  children?: React.ReactNode;
}

function BaseContainer(props: IProps) {
  return (
    <div
      style={{
        background: '#fafafa',
        borderRadius: 4,
        flex: 1,
        margin: '12px 0 0',
        padding: 24,
      }}>
      {props.children}
    </div>
  );
}

export const Container = connect(
  BaseContainer,
  mapProps((props) => ({
    ...props,
  })),
);
