import { useEffect, useMemo, useState } from 'react';
import { Card, Descriptions, Tabs } from 'antd';
import type { UploadFile, DescriptionsProps } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { updateAvatar } from 'src/api/clients/system';
import { Upload } from 'src/components/UploadImage';
import { useUserInfo } from 'src/hooks/useUserInfo';
import dayjs from 'dayjs';
import { BaseInfo } from './components/BaseInfo';
import { Security } from './components/Security';
import styles from './index.module.less';

export default function PersonalProfile() {
  const { userInfo } = useUserInfo();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = async (list: UploadFile[]) => {
    if (list.length > 0 && list[0].status === 'done' && list[0].url) {
      try {
        await updateAvatar({ avatar: list[0].url || list[0].response?.url });
      } catch (error) {
        console.log(error);
      }
    }
    setFileList(list);
  };

  const descriptInfo = useMemo(() => {
    const { username, userId, createTime, phoneNumber, email, dept, roles, posts } = userInfo || {};
    return [
      { key: 'username', label: '用户名', children: username },
      { key: 'userId', label: '账号ID', children: userId },
      {
        key: 'phoneNumber',
        label: '手机号',
        children: phoneNumber ?? '--',
      },
      { key: 'email', label: '邮箱', children: email ?? '--' },
      { ket: 'dept', label: '所属角色', children: roles?.map((i) => i.roleName)?.join(',') },
      { ket: 'dept', label: '所属部门', children: dept?.deptName ?? '--' },
      { ket: 'posts', label: '岗位职称', children: posts?.map((i) => i.name)?.join(',') },
      { key: 'createTime', label: '注册时间', children: dayjs(createTime).format('YYYY-MM-DD') },
    ] as DescriptionsProps['items'];
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.avatar) {
      setFileList([
        {
          uid: '-1',
          name: 'avatar',
          status: 'done',
          url: userInfo?.avatar,
        },
      ]);
    }
  }, [userInfo]);

  return (
    <>
      <Card className="m-4 rounded-md" styles={{ body: { padding: '16px 24px 24px' } }}>
        <div className="text-base font-semibold mb-4">个人中心</div>
        <div className="flex">
          <Upload
            listType="picture-card"
            onChange={handleChange}
            maxCount={1}
            multiple={false}
            isCrop
            isDelete
            isPreview
            fileList={fileList}>
            <button
              style={{ border: 0, background: 'none' }}
              type="button"
              className="cursur-pointer">
              <AiOutlinePlus />
            </button>
          </Upload>
          <Descriptions
            className="flex-1 pl-12 mr-12 w-0"
            items={descriptInfo}
            column={{ xs: 1, sm: 2, lg: 3, xl: 3, xxl: 3 }}></Descriptions>
        </div>
      </Card>
      <Card className="m-4 rounded-md" styles={{ body: { padding: '0px 0px 24px' } }}>
        <Tabs
          defaultActiveKey="1"
          size="large"
          className={styles.profile_tabs}
          items={[
            {
              label: '基本信息',
              key: '1',
              children: <BaseInfo />,
            },
            {
              label: '安全设置',
              key: '2',
              children: <Security />,
            },
          ]}
        />
      </Card>
    </>
  );
}
