import { Button, Card, message, Modal, Typography } from 'antd';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { queryStringToObject } from 'src/utils/tools';
import {
  deleteQuestion,
  getQuestionLibraryDetail,
  getQuestionList,
  createQuestion,
} from 'src/api/clients/exam';
import { QueryTable } from 'src/components/QueryTable';
import { columns } from './schema';
import Empty from 'src/components/Empty';
import { CreateQuestion } from '../components/CreateQuestion';
import { useMemo, useState } from 'react';
import { IQuestionItem } from 'src/api/types/exam';

const QueryQuestionTableName = 'QueryQuestionTable';

const Link = Typography.Link;

export default function Exercise() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const [currentQuestion, setCurrentQuestion] = useState<IQuestionItem>();

  const id = useMemo(() => {
    const params = queryStringToObject(location.search);
    return params.id as string;
  }, [location.search]);

  const { data } = useRequest(async () => {
    if (!id) return null;
    const result = await getQuestionLibraryDetail(id);
    return result;
  });

  const getQuestionListFn = useMemoizedFn(async (params: { offset: number; limit: number }) => {
    const { offset, limit } = params;
    if (!id) {
      message.error('题库ID错误');
    }
    const result = await getQuestionList({
      offset,
      limit,
      questionLibraryId: id,
    });
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onDeleteQuestionById = useMemoizedFn(async (ids: string[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除题目后无法恢复，确定删除吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteQuestion(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn(() => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload: {},
      },
      QueryQuestionTableName,
    );
  });

  const onSubmitForm = useMemoizedFn(
    async (data: Omit<IQuestionItem, 'questionLibraryId' | 'questionId'>) => {
      if (!currentQuestion) {
        await createQuestion({
          ...data,
          questionLibraryId: id,
        });
        message.success('创建成功');
      }
    },
  );

  return (
    <Card
      className="m-4 rounded-md"
      title={
        <div className="flex items-center font-normal">
          <div
            className="text-[#2f88ff] flex items-center select-none cursor-pointer hover:opacity-[0.8] active:opacity-[0.85]"
            onClick={() => navigate('/exam/bank')}>
            <AiOutlineLeft size="14" />
            <span style={{ marginLeft: 4 }}>返回</span>
          </div>
          <div className="mx-6 w-[1px] h-[14px] bg-[#e0e0e0]" />
          <div className="font-semibold text-base leading-[22px] text-black">
            {data?.questionLibraryName}
          </div>
        </div>
      }>
      <div className="flex justify-between items-center mb-1">
        <div className="text-base font-semibold">{t('exam.question')}</div>
        <Button type="primary" onClick={setTrue}>
          新建题目
        </Button>
      </div>
      <QueryTable.Provider
        useQuery={false}
        name={QueryQuestionTableName}
        requestFn={getQuestionListFn}>
        <QueryTable.Table
          columns={[
            ...columns,
            {
              title: '操作',
              fixed: 'right',
              width: 100,
              renderType: 'Operation',
              renderProps: (col: IQuestionItem) => {
                return {
                  buttons: [
                    {
                      label: '编辑',
                      onClick: () => {
                        setCurrentQuestion(col);
                        setTrue();
                      },
                    },
                    {
                      label: '删除',
                      onClick: async () => {
                        if (col.questionId) {
                          await onDeleteQuestionById([col.questionId]);
                        }
                      },
                    },
                  ],
                };
              },
            },
          ]}
          defaultTableHandleActions={[]}
          locale={{
            emptyText: (
              <Empty
                description={
                  <div className="text-sm mb-5">
                    还没有题库内容，请先 <Link onClick={setTrue}>新建题目</Link>
                  </div>
                }
              />
            ),
          }}
        />
      </QueryTable.Provider>
      <CreateQuestion
        open={visible}
        onCancel={() => {
          setFalse();
          onRefresh();
        }}
        data={currentQuestion}
        questionLibraryId={id}
        onChange={onSubmitForm}
      />
    </Card>
  );
}
