import { useMemo, useState } from 'react';
import { useMemoizedFn, useBoolean } from 'ahooks';
import { Input, DatePicker, Select } from '@formily/antd-v5';
import { Button, Card, Modal, message } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteQuestionLibrary, getQuestionLibraryList } from 'src/api/clients/exam';
import { IQueryQuestionLibrary, IQuestionLibraryItem } from 'src/api/types/exam';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { QuestionLibraryModal } from './question-library-modal';
import { QuestionLibraryShare } from './question-library-share';

const ExamQuestionBankTableName = 'ExamQuestionBankTableName';

export default function ExamQuestionBank() {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [current, setCurrent] = useState<IQuestionLibraryItem | null>(null);
  const [openShare, { setTrue: showOpenShare, setFalse: hideOpenShare }] = useBoolean(false);
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const getBankTableList = useMemoizedFn(async (params: IQueryQuestionLibrary) => {
    const { offset, limit, questionLibraryName } = params;
    const result = await getQuestionLibraryList({
      offset,
      limit,
      questionLibraryName,
    });
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onDeleteDataByIds = useMemoizedFn(async (ids: string[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定日志吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteQuestionLibrary(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      ExamQuestionBankTableName,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: IQuestionLibraryItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrent(col);
                  setTrue();
                },
              },
              {
                label: '配置',
                onClick: () => navigator(`/exam/bank/exercise?id=${col.questionLibraryId}`),
              },
              {
                label: '分享',
                onClick: () => {
                  setCurrent(col);
                  showOpenShare();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteDataByIds([col.questionLibraryId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('exam.question_library')}</div>
      <QueryTable.Provider
        useQuery={false}
        name={ExamQuestionBankTableName}
        requestFn={getBankTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select, DatePicker }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="questionLibraryId"
          scroll={{ x: 1200 }}
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
              新建题库
            </Button>
          }
        />
      </QueryTable.Provider>
      <QuestionLibraryModal
        open={visible}
        onCancel={() => {
          setFalse();
          setCurrent(null);
        }}
        onRefresh={onRefresh}
        data={current}
      />
      <QuestionLibraryShare
        open={openShare}
        onCancel={() => {
          hideOpenShare();
          setCurrent(null);
        }}
        data={current}
      />
    </Card>
  );
}
