import { useRef, useMemo, useEffect, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Input } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { IQuestionLibraryItem } from 'src/api/types/exam';
import { createQuestionLibrary, updateQuestionLibrary } from 'src/api/clients/exam';
import { onFormMount } from '@formily/core';
import { createSchema } from './schema';

interface IProps extends ModalProps {
  data: IQuestionLibraryItem | null;
  onRefresh?: () => void;
}

interface IFormData {
  questionLibraryName: string;
  questionLibraryId: string;
}

export function QuestionLibraryModal(props: IProps) {
  const { open, onCancel, data, onRefresh, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);

  const isEdit = !!data?.questionLibraryId;

  const onCancelHandle = useMemoizedFn((e) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<IFormData>();
    const { ...rest } = values ?? {};
    const bodyData = {
      ...rest,
    };
    if (isEdit) {
      bodyData.questionLibraryId = data?.questionLibraryId;
    }
    setTrue();
    const request = isEdit ? updateQuestionLibrary : createQuestionLibrary;
    try {
      const res = await request(bodyData as IFormData);
      if (res) {
        message.success(`${isEdit ? '更新' : '添加'}成功`);
        onCancelHandle(e);
        onRefresh?.();
      }
    } finally {
      setFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      const { questionLibraryName } = data || {};
      formInstance?.setValues({
        questionLibraryName,
      });
    }
  }, [formMounted, data]);

  return (
    <Modal
      open={open}
      title={isEdit ? '编辑题库' : '新增题库'}
      onOk={onSubmit}
      width={500}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      {...extra}>
      <Form
        className="py-4"
        ref={formRef}
        components={{ Input }}
        schema={createSchema}
        formOptions={formOptions}
        hideAction
        layout={{ labelCol: 5, wrapperCol: 19 }}
      />
    </Modal>
  );
}
