import { Modal, Button, Image, message, Spin } from 'antd';
import type { ModalProps, UploadFile } from 'antd';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBoolean } from 'ahooks';
import { imageUrlToFile } from 'src/utils/tools';

interface IProps extends ModalProps {
  onChange: (file: File | null, uploadFile: UploadFile) => Promise<void>;
  file: UploadFile | null;
}

export function CropModal(props: IProps) {
  const { open, onCancel, onChange, file, confirmLoading, ...extra } = props;
  const cropperRef = useRef<Cropper>();
  const [loading, { setTrue: setLoading, setFalse: setUnLoading }] = useBoolean(false);
  const [initLoading, { setTrue: setInitLoading, setFalse: setInitUnLoading }] = useBoolean(false);
  const [previewLoading, { setTrue: setPreviewLoading, setFalse: setPreviewUnLoading }] =
    useBoolean(false);
  const [visible, setVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string>('');

  const handlePreview = () => {
    setPreviewLoading();
    try {
      const cropper = cropperRef.current;
      if (!cropper) {
        return;
      }
      const canvas = cropper.getCroppedCanvas();
      const url = canvas?.toDataURL();
      if (!url) {
        return;
      }
      setImagePreviewSrc(url);
      setVisible(true);
    } catch (err) {
      console.log('预览失败', err);
      message.error('预览失败');
    } finally {
      setPreviewUnLoading();
    }
  };

  const handleCropUpload = () => {
    if (!file) return message.error('请上传图片');
    setLoading();
    try {
      cropperRef.current?.getCroppedCanvas().toBlob((blob) => {
        if (!blob) {
          message.error('剪裁失败');
          return;
        }
        const newFile = new File([blob], file?.name || 'image', {
          type: file?.type || 'image/*',
        });
        onChange(newFile, file);
        setVisible(false);
      });
    } catch (error) {
      console.log('剪裁失败', error);
      message.error('剪裁失败');
    } finally {
      setUnLoading();
    }
  };

  const handleCancel = (e: any) => {
    if (!file) {
      message.error('请上传图片');
      onCancel?.(e);
      return;
    }
    setImageSrc('');
    setImagePreviewSrc('');
    onChange?.(null, file);
    onCancel?.(e);
  };

  const getImageSrc = useCallback(async (file: UploadFile) => {
    if (!file) return '';
    if (!file?.url && !file?.response?.url) {
      const blob = new Blob([file as any]);
      const url = URL.createObjectURL(blob);
      return url;
    }
    const f = await imageUrlToFile(file?.url || file?.response?.url);
    if (!f) return '';
    const blob = new Blob([f]);
    return URL.createObjectURL(blob);
  }, []);

  useEffect(() => {
    if (!file) return;
    setInitLoading();
    getImageSrc(file)
      .then((url) => {
        if (!url) {
          message.error('图片加载失败');
          return;
        }
        setImageSrc(url);
      })
      .finally(() => {
        setInitUnLoading();
      });
  }, [file]);

  return (
    <Modal
      title="裁剪图片"
      width={500}
      open={open}
      closable={false}
      footer={
        <div className="flex justify-end gap-2">
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={handlePreview} loading={previewLoading}>
            预览
          </Button>
          <Button type="primary" loading={confirmLoading || loading} onClick={handleCropUpload}>
            剪裁
          </Button>
        </div>
      }
      {...extra}>
      <Spin spinning={initLoading}>
        <Cropper
          viewMode={2}
          dragMode="move"
          src={imageSrc}
          background={false}
          autoCropArea={0.5}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          checkOrientation={false}
          style={{ height: 300, width: '100%' }}
          onInitialized={(instance) => {
            cropperRef.current = instance;
          }}
        />
        <Image
          width={200}
          style={{ display: 'none' }}
          src={imagePreviewSrc}
          preview={{
            visible,
            src: imagePreviewSrc,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </Spin>
    </Modal>
  );
}
