import { Button } from 'antd';
import {} from 'react';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { PasswordModal } from './components/PassModal';
import { useBoolean } from 'ahooks';

export function Security() {
  const { userInfo } = useUserInfo();
  const [openPassword, { setTrue: showPassword, setFalse: hidePassword }] = useBoolean(false);

  return (
    <>
      <div className="px-6">
        <div className="flex items-center">
          <div className="text-[#4e5969] font-semibold mr-4 pb-5 pt-[30px]">登录密码</div>
          <div className="border-0 border-b border-solid border-[#e5e6eb] flex flex-1  justify-between items-center pb-5 pt-[30px] w-0">
            <span>
              已设置。密码至少6位字符，支持数字、字母和除空格外的特殊字符，且必须同时包含数字和大小写字母。
            </span>
            <Button type="link" onClick={showPassword}>
              修改
            </Button>
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-[#4e5969] font-semibold mr-4 pb-5 pt-[30px]">安全手机</div>
          <div className="border-0 border-b border-solid border-[#e5e6eb] flex flex-1  justify-between items-center pb-5 pt-[30px] w-0">
            <span>
              {userInfo?.phoneNumber ? `已绑定：${userInfo.phoneNumber}` : '您暂未设置安全手机号'}
            </span>
            <Button type="link">{userInfo?.phoneNumber ? '修改' : '设置'}</Button>
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-[#4e5969] font-semibold mr-4 pb-5 pt-[30px]">安全邮箱</div>
          <div className="border-0 border-b border-solid border-[#e5e6eb] flex flex-1  justify-between items-center pb-5 pt-[30px] w-0">
            <span>
              {userInfo?.phoneNumber
                ? `已绑定：${userInfo.email}`
                : '您暂未设置邮箱，绑定邮箱可以用来找回密码、接收通知等。'}
            </span>
            <Button type="link">{userInfo?.email ? '修改' : '设置'}</Button>
          </div>
        </div>
      </div>
      <PasswordModal open={openPassword} onCancel={hidePassword} />
    </>
  );
}
