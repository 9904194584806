import { Tag } from 'antd';
import type { QueryTableColumnProps } from 'src/components/QueryTable';
import { questionTypeMap } from 'src/constants/exam';

export const columns: QueryTableColumnProps = [
  {
    title: '题目名称',
    key: 'body',
    dataIndex: 'body',
    width: 540,
  },
  {
    title: '题型',
    key: 'questionType',
    dataIndex: 'questionType',
    width: 120,
    render: (questionType: number) => {
      return (
        <Tag color="processing">
          {questionTypeMap[questionType as keyof typeof questionTypeMap]}
        </Tag>
      );
    },
  },
];
