export default {
  main: {
    title: '标题',
    setting: {
      theme_title: '主题配置',
      page_mode: '页面模式',
      nav_mode: '导航模式',
      layout_left: '侧边布局',
      layout_top: '顶部布局',
      layout_mix: '混合布局',
      theme_style: '主题样式设置',
      primary_color: '主要色',
      success_color: '成功色',
      error_color: '错误色',
      warning_color: '警戒色',
      font_size: '文字大小',
      border_radius: '圆角',
      wireframe: '线框化',
      page_style: '页面样式设置',
      menu_drag: '侧面菜单拖拽',
      menu_width: '侧面菜单宽度',
      open_keys: '展开所有菜单',
      show_menu: '显示菜单',
      collapsed: '折叠菜单',
      show_collapse: '显示折叠按钮',
      menu_theme: '菜单主题',
      menu_theme_config: {
        light: '亮色',
        dark: '暗色',
      },
      page_theme: '页面主题',
      page_theme_config: {
        normal: '正常模式',
        weakness: '色弱模式',
        grieve: '灰色模式',
      },
      fixed_footer: '固定底部',
      happy_mode: '快乐模式',
      message:
        "配置之后仅是临时生效，要想真正作用于项目，点击下方的 '复制配置' 按钮，将配置替换到 src/configs/settings.json 中即可。",
      copy_button: '复制配置',
      close_button: '关闭',
      reset_button: '重置',
      copy_success: '复制成功，请粘贴到 src/configs/settings.json 文件中',
      reset_title: '重置配置',
      reset_descript: '您确定要重置配置吗？',
    },
    header: {
      exit_full: '退出全屏',
      entry_full: '全屏',
      exit: '退出登录',
      personal: '个人中心',
      default_name: '未知用户',
      setting: '系统配置',
      tabs: {
        close_all: '关闭全部标签页',
        close_other: '关闭其他标签页',
        close_left: '关闭左侧标签页',
        close_right: '关闭右侧标签页',
        close_current: '关闭当前标签页',
      },
    },
    form: {
      fold: '折叠',
      unfold: '展开',
      search: '搜 索',
      reset: '重 置',
    },
    table: {
      refresh: '刷新',
      size: '大小',
      size_config: {
        small: '小',
        middle: '中等',
        large: '大',
      },
      sort: {
        asc: '升序',
        desc: '降序',
      },
      custom: '自定义列',
      download: '下载',
    },
  },
  menu: {
    dashboard: '仪表盘',
    databoard: '数据看板',
    workplace: '工作台',
    system: '系统管理',
    user: '用户管理',
    role: '角色管理',
    menu: '菜单管理',
    dept: '部门管理',
    post: '岗位管理',
    dict: '字典管理',
    log: '日志管理',
    logs: {
      operation: '操作日志',
      login: '登录日志',
    },
    files: '文件管理',
    components: '前端组件库',
    dictionary: '字典管理',
    organization: '组织架构',
    personal: '个人中心',
    personal_message: '消息中心',
    personal_profile: '个人资料',
    exam: '考试管理',
  },
  login_page: '登录页',
  error_page: '错误页',
  error: {
    not_found: '未找到页面',
    server_error: '服务器异常',
    no_permission: '当前用户暂无权限',
    404: '抱歉，您访问的页面不存在',
    go_home: '返回首页',
  },
  request: {
    jsonp_not_url: 'JSONP 需要url参数!',
    jsonp_not_callback: 'JSONP 需要callback参数!',
    exception: {
      400: '请求失败，请检查请求信息',
      401: '用户没有权限访问',
      403: '用户的访问已经被禁止',
      404: '请求资源不存在',
      408: '请求已经超时了',
      410: '请求资源不存在，并且永久丢失了',
      411: '请求被拒绝（缺少Content-Length）',
      415: '不支持的媒体类型',
      500: '服务器端错误',
      501: '服务器端错误（未知的请求方法）',
      502: '服务器端网关错误',
      503: '服务器端不可用',
      504: '网络请求超时',
      505: 'HTTP版本不支持',
      error: '未知错误，请联系管理员',
    },
    system_exception: {
      40000: '请求参数不正确，请检查后重试',
      40001: '当前身份已失效，请重新登录',
      40002: '您不具备该权限，请申请权限',
      40003: '数据不存在',
      40004: '接口不存在',
      40005: '不支持的方法，仅支持GET、POST、PUT、DELETE、HEAD',
      40006: '非法URL',
      40007: '数据重复',
      50000: '系统错误',
      50001: '系统维护',
      50002: '未知错误',
      50003: '数据错误',
      50004: '账户获取异常',
      50100: '服务器内部错误',
      50101: '网络超时，请稍后重试',
      60101: '数据库错误',
      70101: '第三方http错误',
    },
    networkError: '未连接到网络',
  },
  login: {
    descript: '开箱即用的中后台管理系统',
    user_placeholder: '请输入用户名!',
    pass_placeholder: '请输入密码!',
    code_placeholder: '请输入验证码',
    phone_placeholder: '请输入手机号',
    email_placeholder: '请输入邮箱',
    remember: '记住我',
    forget: '忘记密码',
    signin: '登录',
    signup: '注册',
    get_code: '获取验证码',
    go_login: '返回登录',
    go_signup: '快速注册',
    no_account: '没有账号',
    account_exists: '存在账号',
    other_methods: '其他方式登录',
    success_title: '登录成功',
    success_descript: '欢迎回来',
    expire: '登录已过期，请重新登录',
    type: {
      password: '账号登录',
      phone: '手机号登录',
      email: '邮箱登录',
    },
  },
  databoard: {
    pv: '页面浏览量',
    uv: '独立访问用户数',
    click: '内容点击量',
    month: '月活跃用户人数',
    total: '数据总览',
    today: '今日统计',
    distribution: '访问应用分布',
    today_pv: '今日页面浏览量',
    month_add_user: '本月新增用户',
    month_add_order: '本月新增订单',
    retention_trend: '用户留存趋势',
    retention_count: '用户留存量',
  },
  workplace: {
    early: '清晨好，',
    early_desc: '希望你迎接新的一天。',
    morning: '早上好，',
    morning_desc: '今天又是美好的一天!',
    noon: '中午好，',
    noon_desc: '让我们继续努力!',
    afternoon: '下午好，',
    afternoon_desc: '今天也要努力工作哦。',
    evening: '晚上好，',
    evening_desc: '愿你有个宁静的夜晚。',
    night: '夜深了，',
    night_desc: '记得早点休息哦。',
    progress: '项目进度',
    dynamics: '项目动态',
    source: '用户来源',
    more: '更多',
    help: '帮助文档',
    manage: '管理',
    quick: '快捷入口',
    schedule: '日程待办',
    notice: '公告',
  },
  exam: {
    question_library: '题库管理',
    question: '题目列表',
    testpaper: '试卷管理',
  },
};
