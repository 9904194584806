/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const UsersManage = lazy(() => import('src/pages/system/users'));
const MenuManage = lazy(() => import('src/pages/system/menus'));
const RoleManage = lazy(() => import('src/pages/system/roles'));
const PostManage = lazy(() => import('src/pages/system/posts'));
const DeptManage = lazy(() => import('src/pages/system/depts'));
const DictManage = lazy(() => import('src/pages/system/dicts'));
const NoticeManage = lazy(() => import('src/pages/system/notice'));
const LogLoginManage = lazy(() => import('src/pages/system/log/login'));
const LogOperManage = lazy(() => import('src/pages/system/log/operation'));
const FilesManage = lazy(() => import('src/pages/system/files'));

const systemRoute: RouteObject[] = [
  {
    path: '/system',
    element: <Layout />,
    name: '系统管理',
    children: [
      {
        path: 'users',
        name: '用户管理',
        element: <UsersManage />,
      },
      {
        path: 'roles',
        name: '角色管理',
        element: <RoleManage />,
      },
      {
        path: 'menus',
        name: '菜单管理',
        element: <MenuManage />,
      },
      {
        path: 'depts',
        name: '部门管理',
        element: <DeptManage />,
      },
      {
        path: 'posts',
        name: '岗位管理',
        element: <PostManage />,
      },
      {
        path: 'dicts',
        name: '字典管理',
        element: <DictManage />,
      },
      {
        path: 'notice',
        name: '通知公告',
        element: <NoticeManage />,
      },
      {
        path: 'logs',
        name: '日志管理',
        children: [
          {
            path: 'login',
            name: '登录日志',
            element: <LogLoginManage />,
          },
          {
            path: 'operlog',
            name: '操作日志',
            element: <LogOperManage />,
          },
        ],
      },
      {
        path: 'files',
        name: '文件管理',
        element: <FilesManage />,
      },
    ],
  },
];

export default systemRoute;
