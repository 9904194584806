import { useCallback, useRef } from 'react';
import { Alert, message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Form, FormRef } from 'src/components/Form';
import { Password } from '@formily/antd-v5';
import { schema } from './schema';
import { Field, onFieldInputValueChange } from '@formily/core';
import { useBoolean } from 'ahooks';
import { updatePassword } from 'src/api/clients';
import { userStore } from 'src/stores/user';

export function PasswordModal(props: ModalProps) {
  const { onCancel, ...rest } = props;

  const formRef = useRef<FormRef | null>(null);

  const [loading, { setTrue: showLoading, setFalse: hideLoading }] = useBoolean();

  const { logout } = userStore((store) => ({
    logout: store.logout,
  }));

  const handleCancel = (e: any) => {
    onCancel?.(e);
  };

  const handleSubmit = async () => {
    const instance = formRef.current?.getFormInstance();
    const result = await instance?.submit<{
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    }>();
    const { oldPassword = '', newPassword = '', confirmPassword = '' } = result || {};
    if (oldPassword === newPassword) {
      message.error('新密码不能与旧密码相同');
      return;
    }
    if (newPassword !== confirmPassword) {
      message.error('新密码与确认密码不一致');
      return;
    }
    showLoading();
    try {
      await updatePassword({
        oldPassword,
        newPassword,
      });
      message.success('修改密码成功');
      logout();
    } finally {
      hideLoading();
    }
  };

  const effects = useCallback(() => {
    onFieldInputValueChange('oldPassword', (field) => {
      const oldPassword = field.value;
      const newPassword = field.query('newPassword').get('value'); // 获取新密码值
      if (newPassword && newPassword === oldPassword) {
        field.setSelfErrors(['新密码不能与旧密码相同']);
      } else {
        field.setSelfErrors([]);
      }
    });

    onFieldInputValueChange('newPassword', (field) => {
      const newPassword = field.value;
      const oldPasswordField = field.query('oldPassword').take();
      const oldPassword = (oldPasswordField as Field)?.value || '';
      const confirmPasswordField = field.query('confirmPassword').take();
      const confirmPassword = (confirmPasswordField as Field)?.value || '';
      if (newPassword && newPassword === oldPassword) {
        field.setSelfErrors(['新密码不能与旧密码相同']);
      } else {
        field.setSelfErrors([]);
      }
      if (newPassword && confirmPassword && confirmPassword !== newPassword) {
        (confirmPasswordField as Field).setSelfErrors(['新密码与确认密码不一致']);
      } else {
        (confirmPasswordField as Field).setSelfErrors([]);
      }
    });
    onFieldInputValueChange('confirmPassword', (field) => {
      const confirmPassword = field.value;
      const newPassword = field.query('newPassword').get('value'); // 获取新密码值
      if (!newPassword && confirmPassword) {
        field.setSelfErrors(['请先输入新密码']);
      } else if (newPassword && confirmPassword !== newPassword) {
        field.setSelfErrors(['新密码与确认密码不一致']);
      } else {
        field.setSelfErrors([]);
      }
    });
  }, []);

  return (
    <Modal
      title="修改密码"
      onCancel={handleCancel}
      {...rest}
      confirmLoading={loading}
      onOk={handleSubmit}>
      <Alert message="注意：修改密码后需要重新登录" type="warning" showIcon />
      <Form
        className="py-6"
        ref={formRef}
        layout={{ labelWidth: 90 }}
        schema={schema}
        components={{ Password }}
        formOptions={{ effects }}
        hideAction
      />
    </Modal>
  );
}
