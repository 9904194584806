import { Select } from 'antd';
import type { SelectProps } from 'antd';

import { connect, mapProps } from '@formily/react';
import Empty from 'src/components/Empty';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import { getUserList } from 'src/api/clients';
import { debounce } from 'lodash-es';
import { Field } from '@formily/core';

interface IProps extends SelectProps {
  onChange?: (value: number | number[]) => void;
}

function BaseUserSelect(props: IProps) {
  const { onChange, value, ...rest } = props;
  const [data, setData] = useState<SelectProps['options']>([]);
  const { loading, run } = useRequest(
    async (search: string) => {
      const res = await getUserList({
        offset: 0,
        limit: 100,
        username: search,
      });
      const data = res.rows.map((item) => {
        return {
          value: item.userId,
          label: item.username,
        };
      });
      setData(data);
    },
    { manual: true },
  );

  const handleSearch = debounce((search: string) => {
    run(search);
  }, 800);
  return (
    <Select
      {...rest}
      value={value}
      style={{ width: '100%' }}
      tokenSeparators={[',']}
      options={data}
      showSearch
      loading={loading}
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={<Empty />}
      onChange={onChange}
    />
  );
}

export const UserSearch = connect(
  BaseUserSelect,
  mapProps((props, field) => ({
    ...props,
    value: props.value || (field as Field).value,
    onChange: (field as Field).onInput,
  })),
);
