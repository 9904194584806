import type { IFormSchema } from 'src/components/Form';

export const schema: IFormSchema = {
  oldPassword: {
    type: 'string',
    title: '原密码',
    'x-component': 'Password',
    'x-component-props': {
      placeholder: '请输入原密码',
    },
    'x-decorator': 'FormItem',
    'x-validator': [{ required: true, message: '请输入原密码' }],
  },
  newPassword: {
    type: 'string',
    title: '新密码',
    'x-component': 'Password',
    'x-component-props': {
      placeholder: '请输入新密码',
      checkStrength: true,
    },
    'x-decorator-props': {
      asterisk: true,
    },
    'x-decorator': 'FormItem',
    'x-validator': [{ required: true, message: '请输入新密码' }],
  },
  confirmPassword: {
    type: 'string',
    title: '确认密码',
    'x-component': 'Password',
    'x-component-props': {
      placeholder: '请再次输入新密码',
    },
    'x-decorator': 'FormItem',
    'x-decorator-props': {
      asterisk: true,
    },
  },
};
