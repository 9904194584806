import { useEffect, useState } from 'react';
import type { CancelTokenSource } from 'axios';
import { useMemoizedFn } from 'ahooks';
import { Upload, Modal, message, Progress, Spin } from 'antd';
import type { UploadProps, ModalProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib';
import { createUploadFile, verifyFile, mergeFile } from 'src/api/clients/tools';
import { MAX_FILE_SIZE, CHUNK_SIZE, MAX_RETRIES } from 'src/constants/upload';
import {
  AiOutlinePlus,
  AiOutlineCloudUpload,
  AiOutlinePause,
  AiOutlineDelete,
} from 'react-icons/ai';
import classes from './index.module.less';
import axios from 'axios';
import { cloneDeep, isFunction } from 'lodash-es';
import clsx from 'clsx';
import { IFilesInfo } from 'src/api/types/system';

const { Dragger } = Upload;

interface IProps extends ModalProps {
  uploadProps?: UploadProps;
  children?: React.ReactNode;
  onChange?: (fileList: UploadFile[]) => void;
  title?: React.ReactNode;
  name?: React.ReactNode;
  desc?: React.ReactNode;
  maxSize?: number;
  minSize?: number;
  // 支持的文件类型
  accept?: string;
  // 切片大小
  chunkSize?: number;
  // 最大重试次数
  maxRetries?: number;
  onDelete?: (file: IUploadFile) => void;
  onSubmit?: (fileList: UploadFile[]) => Promise<void>;
  onCancel?: () => void;
}

type UploadStatus = UploadFile['status'] & 'paused';

interface IUploadFile extends UploadFile {
  chunkPercent?: Record<string, number>;
  status?: UploadStatus;
  getNameLoading?: boolean;
}

export function UploadFileModal(props: IProps) {
  const {
    open,
    onCancel,
    uploadProps,
    children,
    onChange,
    title = '上传文件',
    name = '点击或拖拽文件到此上传',
    desc = '仅支持单次上传文件',
    maxSize = MAX_FILE_SIZE,
    minSize = 0,
    accept,
    chunkSize = CHUNK_SIZE,
    maxRetries = MAX_RETRIES,
    onDelete,
    onSubmit,
    ...extra
  } = props;
  const [files, setFiles] = useState<IUploadFile[]>([]);

  const [fileNameWorker, setFileNameWorker] = useState<Worker | null>(null);
  const [cancelTokens, setCancelTokens] = useState<Record<string, CancelTokenSource[]>>({});
  const [response, setResponse] = useState<Record<string, IFilesInfo>>({});
  const resetAllStatus = useMemoizedFn(() => {
    setCancelTokens({});
  });

  const onCancelHandle = useMemoizedFn(() => {
    setFiles([]);
    setCancelTokens({});
    onCancel?.();
  });
  const onSubmitHandle = useMemoizedFn(async () => {
    await onSubmit?.(
      cloneDeep(files).map((file) => ({
        ...file,
        response: response[file.uid],
      })),
    );
    onCancelHandle();
  });

  const onUploadChangeHandle = useMemoizedFn((info: UploadChangeParam<UploadFile<any>>) => {
    const currentFiles = [...files, info.file] as IUploadFile[];
    setFiles(currentFiles);
    onChange?.(currentFiles);
  });

  const onBeforeUpload = useMemoizedFn((file: UploadFile<any>) => {
    if (file.status === 'done') {
      return false;
    }
    if (file.size && file.size > maxSize) {
      message.error(`文件大小超过限制，请上传小于${maxSize / 1024 / 1024}MB的文件`);
      return false;
    }
    if (file.size && file.size < minSize) {
      message.error(`文件大小小于限制，请上传大于${minSize / 1024 / 1024}MB的文件`);
      return false;
    }
    if (file.type && accept && !accept.includes(file.type)) {
      message.error(`文件类型不支持，请上传${accept}类型的文件`);
      return false;
    }
    return true;
  });

  function createFileChunks(file: RcFile, fileName: string) {
    //最后切成的分片的数组
    const chunks = [];
    //计算一共要切成多少片
    const count = Math.ceil(file.size / chunkSize);
    for (let i = 0; i < count; i++) {
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
      chunks.push({
        chunk,
        chunkFileName: `${fileName}-${i}`,
      });
    }
    return chunks;
  }

  const findFileInfo = useMemoizedFn((file: UploadFile) => {
    const currentFiles = [...files];
    const currentFileIndex = currentFiles.findIndex((item) => item.uid === file.uid);
    const currentFile =
      currentFileIndex > -1 ? currentFiles.splice(currentFileIndex, 1)?.[0] : file;
    return [currentFile, currentFileIndex] as [IUploadFile, number];
  });

  const onUploadFileProgress = useMemoizedFn(
    (file: IUploadFile, chunkFileName: string) => (value: number) => {
      const currentFiles = [...cloneDeep(files)];
      const [currentFile, currentFileIndex] = findFileInfo(file);

      const chunkPercent = {
        ...cloneDeep(currentFile?.chunkPercent || {}),
        [chunkFileName]: value,
      };

      const percents = Object.values(chunkPercent);

      const computedTotal = Math.round(
        percents.reduce((acc, curr) => acc + curr, 0) / percents.length,
      );
      const totalPercent = Number.isNaN(computedTotal) ? 0 : computedTotal;
      currentFiles[currentFileIndex] = {
        ...cloneDeep(currentFile),
        chunkPercent,
        percent: Number.isNaN(totalPercent)
          ? 0
          : totalPercent > (file.percent ?? 0)
            ? totalPercent
            : file.percent,
      };
      onChange?.(currentFiles);
      setFiles(currentFiles);
    },
  );

  const uploadFileRequest = async (uploadInfo: {
    file: RcFile;
    fileName: string;
    onSuccess?: (...params: any) => void;
    retryCount?: number;
  }) => {
    const { file, fileName, retryCount = 0 } = uploadInfo;
    const { needUpload, uploadedChunkList } = await verifyFile(fileName);
    const [currentFile, currentFileIndex] = findFileInfo(file);
    if (!needUpload) {
      message.info('文件已存在，无需重复上传');
      // 标识文件上传完毕
      setFiles((pre) => {
        if (!currentFile) return pre;
        const currentFiles = [...cloneDeep(pre)];
        currentFiles[currentFileIndex] = {
          ...currentFile,
          status: 'done' as UploadStatus,
          percent: 100,
        };
        return currentFiles;
      });
      return;
    }
    if ((file as IUploadFile).status !== 'uploading') {
      if (!currentFile) return;
      currentFile.status = 'uploading' as UploadStatus;
      const filesList = [...cloneDeep(files)];
      filesList[currentFileIndex] = currentFile;
      setFiles(filesList);
    }
    // 切片个数
    const chunks = createFileChunks(file, fileName);
    const newCancelTokens = [] as CancelTokenSource[];
    const requests = chunks.map(({ chunk, chunkFileName }) => {
      const cancelToken = axios.CancelToken.source();
      newCancelTokens.push(cancelToken);
      //以后往服务器发送的数据可能就不再是完整的分片的数据
      //判断当前的分片是否是已经上传过服务器了,
      const existingChunk = uploadedChunkList.find((uploadedChunk) => {
        return uploadedChunk.chunkFileName == chunkFileName;
      });

      const setUploadProgress = onUploadFileProgress(file, chunkFileName);

      //如果存在existingChunk，说明此分片已经上传过一部分了，或者说已经完全 上传完成
      if (existingChunk) {
        //获取已经上传的分片的大小，上次结束的位置 是不是就是下次开始的位置
        const uploadedSize = existingChunk.size;
        //从chunk中进行截取，过滤掉已经上传过的大小,得到剩下需要继续上传的内容
        const remainingChunk = chunk.slice(uploadedSize);
        //如果剩下的数据为0，说明完全 上传完毕
        if (remainingChunk.size === 0) {
          setUploadProgress(100);
          return Promise.resolve();
        }
        // 100个字节，第一次上传的时候60个字节，暂停了
        //下次再传的是传剩下的40字节，从哪个位置开始写，要从上次结束的位置 ，也就是60开始写
        //如果剩下的数据还有，则需要继续上传剩余的部分 总计100字节，已经传60字节，继续传剩下的40个字节，写入文件的起始索引60
        setUploadProgress((uploadedSize * 100) / chunk.size);
        return createUploadFile({
          filename: fileName,
          chunkFileName,
          chunk: remainingChunk,
          setUploadProgress,
          cancelToken,
          start: uploadedSize,
          totalSize: chunk.size,
        });
      } else {
        return createUploadFile({
          filename: fileName,
          chunkFileName,
          chunk,
          setUploadProgress,
          cancelToken,
          start: 0,
          totalSize: chunk.size,
        });
      }
    });
    setCancelTokens({ ...cancelTokens, [file.uid]: newCancelTokens });
    try {
      //并行上传每个分片
      await Promise.all(requests);
      //等全部的分片上传完了，会向服务器发送一个合并文件的请求
      const result = await mergeFile(fileName);
      message.success('文件上传完成');
      setFiles((pre) => {
        if (!currentFile) return pre;
        const currentFiles = [...cloneDeep(pre)];
        currentFiles[currentFileIndex] = {
          ...currentFile,
          status: 'done' as UploadStatus,
          percent: 100,
        };
        return currentFiles;
      });
      setResponse({
        ...response,
        [file.uid]: {
          ...result,
          fileName: fileName,
          originalName: file.name,
          size: `${file.size}`,
          fileKey: result.key,
        },
      });
      resetAllStatus();
    } catch (error) {
      //如果是由于用户主动点击了暂停的按钮，暂停了上传
      if (axios.isCancel(error)) {
        message.warning('上传暂停');
      } else {
        if (retryCount < maxRetries) {
          console.log(`上传出错了，重试中...`);
          uploadFileRequest({
            file,
            fileName,
            retryCount: retryCount + 1,
          });
        } else {
          console.log('上传出错', error);
          message.error('上传出错');
        }
      }
    }
  };

  const onPauseHandle = useMemoizedFn((file: IUploadFile) => {
    const currentFiles = [...files];
    const [currentFile, currentFileIndex] = findFileInfo(file);
    currentFile.status = 'paused' as UploadStatus;
    currentFiles[currentFileIndex] = currentFile;
    setFiles(currentFiles);
    // 取消上传
    const tokens = cancelTokens[file.uid];
    tokens.forEach((token) => {
      token.cancel('上传暂停');
    });
  });

  const onDeleteHandle = (file: IUploadFile) => {
    const index = files.findIndex((item) => item.uid === file.uid);
    if (index < 0) {
      return;
    }
    setFiles((prev) => {
      prev.splice(index, 1);
      if (isFunction(onChange)) {
        onChange?.(files);
      }
      return [...prev];
    });
    setResponse((prev) => {
      const newResponse = { ...prev };
      delete newResponse[file.uid];
      return newResponse;
    });
    // 取消上传
    cancelTokens[file.uid]?.forEach((token) => {
      token.cancel('上传取消');
    });
    if (isFunction(onDelete)) {
      onDelete(file);
    }
  };

  const handleCustomRequest = async (options: any) => {
    if (uploadProps?.customRequest) return uploadProps?.customRequest(options);
    const { file } = options;
    let fileName = file.name;
    const [currentFile, currentFileIndex] = findFileInfo(file);
    if (fileNameWorker) {
      fileNameWorker.postMessage(file);
      setFiles((pre) => {
        const currentFiles = [...cloneDeep(pre)];
        if (!currentFile) return currentFiles;
        currentFiles[currentFileIndex] = {
          ...currentFile,
          getNameLoading: true,
        };
        return [...currentFiles];
      });

      //监听WebWorker发过来的的消息，接收计算好的文件名
      fileNameWorker.onmessage = async (event) => {
        setFiles((pre) => {
          const currentFiles = [...cloneDeep(pre)];
          if (!currentFile) return currentFiles;
          currentFiles[currentFileIndex] = {
            ...currentFile,
            getNameLoading: false,
          };
          return [...currentFiles];
        });
        fileName = event.data;
        uploadFileRequest({ file, fileName });
      };
      return;
    }
    await uploadFileRequest({ file, fileName });
  };

  const renderOperator = useMemoizedFn((file) => {
    return (
      <div className="flex justify-start items-center text-base gap-2">
        {file.status === 'uploading' && (
          <AiOutlinePause onClick={() => onPauseHandle(file)} className="cursor-pointer" />
        )}
        {file.status === 'paused' && (
          <AiOutlineCloudUpload
            className="cursor-pointer"
            onClick={() => {
              console.log('继续上传');
              handleCustomRequest({ file: file.originFileObj });
            }}
          />
        )}
        <AiOutlineDelete onClick={() => onDeleteHandle(file)} className="cursor-pointer" />
      </div>
    );
  });

  // 初始化文件名worker
  useEffect(() => {
    if (isFunction(window.crypto?.subtle?.digest)) {
      const fileNameWorker = new Worker('/filenameWorker.js');
      setFileNameWorker(fileNameWorker);
    }
  }, []);

  return (
    <Modal
      open={open}
      // onCancel={onCancelHandle}
      closeIcon={null}
      title={title}
      okText="提交"
      cancelText="取消"
      cancelButtonProps={{
        onClick: onCancelHandle,
      }}
      {...extra}
      onOk={onSubmitHandle}>
      <Dragger
        {...uploadProps}
        accept={accept || '*'}
        fileList={files}
        onChange={onUploadChangeHandle}
        showUploadList={false}
        className="upload"
        beforeUpload={onBeforeUpload}
        customRequest={handleCustomRequest}>
        {children ? (
          children
        ) : (
          <div className={classes['upload-wrap']}>
            <div className={classes['icon-wrap']}>
              <AiOutlinePlus />
            </div>
            <div className={classes['tip-title']}>{name}</div>
            <div className={classes['tip-desc']}>{desc}</div>
          </div>
        )}
      </Dragger>
      <div className="max-h-[150px] overflow-x-hidden overflow-y-auto my-2">
        {files?.map((file) => {
          return (
            <Spin spinning={file.getNameLoading} tip="计算文件名..." key={file.uid}>
              <div
                className={clsx(
                  'border rounded p-3 bg-[#fafafa] mb-2',
                  file.status && ['uploading', 'paused'].includes(file.status)
                    ? 'border-dashed'
                    : 'border-solid',
                  file.status === 'error' ? 'border-red-500' : 'border-gray-300',
                )}>
                <div className="flex justify-between items-center w-full">
                  <div>{file.fileName || file.name}</div>
                  <div className="shrink-0">{renderOperator(file)}</div>
                </div>
                {file.status && ['uploading', 'paused'].includes(file.status) ? (
                  <Progress percent={file.percent} />
                ) : null}
              </div>
            </Spin>
          );
        })}
      </div>
    </Modal>
  );
}
