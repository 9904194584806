import { Radio, Checkbox, Input } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';
import { numberToLetter } from 'src/utils/tools';
import { connect, mapProps, useField } from '@formily/react';
import { Field } from '@formily/core';
interface IProps {
  value: string;
  onValueChange: (v: string) => void;
  deleteChoiceItem: () => void;
  multiple?: boolean;
  showDelete: boolean;
  errorText?: string;
}
function BaseChoiceItem(props: IProps) {
  const { value, onValueChange, deleteChoiceItem, multiple, showDelete, errorText } = props;
  const field = useField();

  return (
    <>
      <div className="my-2 flex justify-between items-center">
        {multiple ? (
          <Checkbox value={field.index}>
            <div className="min-w-6 text-[1f2329] text-sm mr-[-8px] flex-auto">
              {numberToLetter(field.index + 1)}.{' '}
            </div>
          </Checkbox>
        ) : (
          <Radio value={field.index + ''}>
            <div className="min-w-6 text-[1f2329] text-sm mr-[-8px] flex-auto">
              {numberToLetter(field.index + 1)}.{' '}
            </div>
          </Radio>
        )}
        <Input
          placeholder="请输入选项"
          style={{ width: '100%' }}
          value={value}
          onChange={(e) => {
            onValueChange(e.target.value);
          }}
          status={errorText ? 'error' : undefined}></Input>
        {showDelete ? (
          <div className="cursor-pointer ml-3" onClick={deleteChoiceItem}>
            <AiOutlineDelete size="14" fill="#999999" strokeLinejoin="miter" strokeLinecap="butt" />
          </div>
        ) : null}
      </div>
      {errorText ? (
        <div
          className="text-[#f54a45] pl-12 mt-[-8px] mb-2 text-sm leading-[22px] min-h-[22px]"
          style={{ fontSize: '12px' }}>
          {errorText}
        </div>
      ) : null}
    </>
  );
}

export const ChoiceItem = connect(
  BaseChoiceItem,
  mapProps((props, field) => ({
    ...props,
    value: (field as Field).value,
    onValueChange: (field as Field).onInput,
  })),
);
