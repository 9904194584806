import { observer, useField } from '@formily/react';
import { Field } from '@formily/react';
import type { ArrayField, Field as FieldType } from '@formily/core';
import { Checkbox, Radio } from '@formily/antd-v5';
import { ChoiceItem } from './ChoiceItem';
import { cloneDeep } from 'lodash-es';
import { guid } from 'src/utils/tools';
import { useEffect, useState } from 'react';
interface IProps {
  // 题目结果与选中数据
  multiple?: boolean;
  isJudgment?: boolean;
}

// value => { value: string, isAnswer: boolean, id: string  }

function BaseChoice(props: IProps) {
  const { multiple, isJudgment } = props;

  const field = useField<ArrayField>();
  // 用于单独处理判断题
  const [isAnswer, setIsAnswer] = useState<number>(field.value?.findIndex((i) => i.isAnswer));
  // 选项value
  const choices = Array.isArray(field.value) ? field.value : [];

  const addChoiceItem = () => {
    field.push({ value: '', isAnswer: false, id: guid() });
  };
  const deleteChoiceItem = async (id: string) => {
    const index = choices.findIndex((item) => item.id === id);
    field.remove(index);
  };
  const onMultipleChange = (val: number[]) => {
    const currentChoice = cloneDeep(choices);
    currentChoice.forEach((item, i) => {
      item.isAnswer = val.includes(i);
    });
    field.setValue(currentChoice);
  };
  const onChange = (index: number) => {
    const currentChoice = cloneDeep(choices);
    currentChoice.forEach((item, i) => {
      item.isAnswer = i === index;
    });
    setIsAnswer(index);
    field.setValue(currentChoice);
  };

  useEffect(() => {
    choices?.forEach((_item, index) => {
      const childItem = field.query(`options.${index}.value`).take();
      if (childItem) {
        childItem.componentProps.multiple = multiple;
      }
    });
  }, [multiple, choices?.length]);

  useEffect(() => {
    if (isJudgment) {
      setIsAnswer(choices?.findIndex((i) => i.isAnswer));
    }
  }, [isJudgment, choices]);

  return (
    <div className="choice">
      <div className="w-full">
        {multiple ? (
          <Checkbox.Group
            className="full !block"
            value={choices
              ?.map((i, index) => ({ ...i, index }))
              .filter((item) => item.isAnswer)
              ?.map((item) => item.index)}
            onChange={(i) => {
              onMultipleChange(i);
            }}>
            {choices.map((item, index) => {
              return (
                <Field
                  key={index}
                  name={`${index}.value`}
                  component={[
                    ChoiceItem,
                    {
                      showDelete: false,
                      deleteChoiceItem: () => deleteChoiceItem(item.id),
                      index,
                    },
                  ]}
                  validator={(value) => {
                    const childItem = field.query(`options.${index}.value`).take();
                    if (!value) {
                      (childItem as FieldType)?.setComponentProps({
                        errorText: '请填写选项内容',
                      });
                      return '请填写选项内容';
                    }
                    (childItem as FieldType)?.setComponentProps({
                      errorText: undefined,
                    });
                    return null;
                  }}
                  reactions={(field) => {
                    field.setComponentProps({
                      multiple,
                      showDelete: choices.length > 2,
                    });
                  }}></Field>
              );
            })}
          </Checkbox.Group>
        ) : isJudgment ? (
          <Radio.Group
            className="w-full !block"
            value={isAnswer}
            onChange={(e) => {
              onChange(Number(e.target.value));
            }}
            options={choices?.map((item, index) => ({
              label: item.value,
              value: index,
            }))}
          />
        ) : (
          <Radio.Group
            className="w-full !block"
            value={`${choices?.findIndex((i) => i.isAnswer)}`}
            onChange={(e) => {
              onChange(Number(e.target.value));
            }}>
            {choices.map((item, index) => {
              return (
                <Field
                  key={index}
                  name={`${index}.value`}
                  component={[
                    ChoiceItem,
                    {
                      showDelete: false,
                      deleteChoiceItem: () => deleteChoiceItem(item.id),
                      index,
                    },
                  ]}
                  validator={(value) => {
                    const childItem = field.query(`options.${index}.value`).take();
                    if (!value) {
                      (childItem as FieldType)?.setComponentProps({
                        errorText: '请填写选项内容',
                      });
                      return '请填写选项内容';
                    }
                    (childItem as FieldType)?.setComponentProps({
                      errorText: undefined,
                    });
                    return null;
                  }}
                  reactions={(field) => {
                    field.setComponentProps({
                      multiple,
                      showDelete: choices.length > 2,
                    });
                  }}></Field>
              );
            })}
          </Radio.Group>
        )}
      </div>
      <span className="cursor-pointer text-[#338aff]" onClick={addChoiceItem}>
        + 添加选项
      </span>
    </div>
  );
}

export const Choice = observer(BaseChoice);
