import { useHover } from 'ahooks';
import { UploadFile, Image, Spin } from 'antd';
import clsx from 'clsx';
import { AiOutlineEye, AiOutlineDelete } from 'react-icons/ai';
import { CiCrop } from 'react-icons/ci';
import { useMemo, useRef, useState } from 'react';

interface IProps {
  isPreview?: boolean;
  isDelete?: boolean;
  isCrop?: boolean;
  file: UploadFile;
  onRemove?: (file: UploadFile) => void;
  onCrop?: (uploadFile: UploadFile) => Promise<void>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export function Preview(props: IProps) {
  const { file, onRemove, onCrop, style, children, isPreview, isCrop, isDelete } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHover(ref);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const isNotAction = useMemo(() => {
    return !isPreview && !isCrop && !isDelete;
  }, [isPreview, isCrop, isDelete]);

  const renderFile = () => {
    const uploadFile = file;
    const fileBlob = uploadFile?.originFileObj as File;
    const newFile = new File([fileBlob], uploadFile.name);
    const url = URL.createObjectURL(newFile);
    const src = uploadFile.url ?? url;
    return (
      <div key={uploadFile.uid} data-index={uploadFile.uid} className="relative" ref={ref}>
        <Spin spinning={['uploading', 'init'].includes(uploadFile.status || '')}>
          <div
            className=" w-[100px] h-[100px] relative flex justify-center items-center"
            style={{
              width: style?.width || 100,
              height: style?.height || 100,
            }}>
            <Image
              src={src}
              preview={false}
              height={style?.height || 100}
              width={style?.width || 100}
            />
            {isNotAction ? null : (
              <div
                className={clsx(
                  'absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] text-[#FFFFFF] flex justify-center items-center gap-1',
                  isHovering ? 'visible' : 'invisible',
                )}>
                {isPreview ? (
                  <AiOutlineEye onClick={() => setPreviewUrl(src)} className="cursor-pointer" />
                ) : null}
                {isDelete ? (
                  <AiOutlineDelete
                    onClick={() => onRemove?.(uploadFile)}
                    className="cursor-pointer"
                  />
                ) : null}
                {isCrop ? (
                  <CiCrop onClick={async () => await onCrop?.(file)} className="cursor-pointer" />
                ) : null}
              </div>
            )}
          </div>
        </Spin>
      </div>
    );
  };

  return (
    <>
      <div
        className="flex justify-center items-center flex-col border border-solid border-[#e5e7eb] rounded-lg overflow-hidden"
        style={style}>
        {renderFile()}
        {children}
      </div>
      <Image
        style={{ display: 'none' }}
        preview={{
          visible: !!previewUrl,
          onVisibleChange: (visible) => {
            if (!visible) {
              setPreviewUrl(null);
            }
          },
          src: previewUrl || '',
        }}></Image>
    </>
  );
}
