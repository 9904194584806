import { message } from 'antd';
import { HttpStatusCodeEnum, SystemStatusCodeEnum } from 'src/interface';
import { HttpRequest } from 'src/utils/request';
import { getToken, removeToken } from 'src/utils/cookie';
import {
  encrypt,
  decrypt,
  encryptBase64,
  encryptWithAes,
  generateAesKey,
  decryptWithAes,
  decryptBase64,
} from 'src/utils/crypto';
import { t } from 'src/locales';
import { SystemExceptionCodeToMessage as SystemExceptionFn } from 'src/constants/system';
import axios, { AxiosError } from 'axios';

const SystemExceptionCodeToMessage = SystemExceptionFn();

const urlPrefix = import.meta.env.PUBLIC_BASE_URL_PREFIX;
const clientId = import.meta.env.PUBLIC_APP_CLIENT_ID;
// const isEncrypt = import.meta.env.PUBLIC_APP_ENCRYPT;
const isEncrypt = false;

const encryptHeader = 'encrypt-key';
const http = new HttpRequest({
  baseURL: urlPrefix,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    clientid: clientId,
  },
});

http.setRequestInterceptors((config) => {
  // 提醒网络错误
  if (!navigator.onLine) {
    message.error(t('request.networkError'));
    return Promise.reject();
  }
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;

  // pust、put请求加密
  if (isEncrypt && (config.method === 'post' || config.method === 'put')) {
    const aesKey = generateAesKey();
    config.headers[encryptHeader] = encrypt(encryptBase64(aesKey));
    config.data =
      typeof config.data === 'object'
        ? encryptWithAes(JSON.stringify(config.data), aesKey)
        : encryptWithAes(config.data, aesKey);
  }

  // FormData数据去请求头Content-Type
  if (config.data instanceof FormData) {
    delete config.headers['Content-Type'];
  }
  return config;
});

http.setResponseInterceptors(
  (config) => {
    if (isEncrypt) {
      // 加密后的 AES 秘钥
      const keyStr = config.headers[encryptHeader];
      // 加密
      if (keyStr != null && keyStr != '') {
        const data = config.data;
        // 请求体 AES 解密
        const base64Str = decrypt(keyStr);
        // base64 解码 得到请求头的 AES 秘钥
        const aesKey = decryptBase64(base64Str.toString());
        // aesKey 解码 data
        const decryptData = decryptWithAes(data, aesKey);
        // 将结果 (得到的是 JSON 字符串) 转为 JSON
        config.data = JSON.parse(decryptData);
      }
    }
    // 状态码 小于 205 则认为请求成功
    if (config.status < HttpStatusCodeEnum.RESET_CONTENT) {
      const requestCode = Number(config.data.code);
      const errorMessage =
        config?.data?.message ||
        SystemExceptionCodeToMessage[requestCode as keyof typeof SystemExceptionCodeToMessage];
      // 二进制数据则直接返回
      if (config.request.responseType === 'blob' || config.request.responseType === 'arraybuffer') {
        return config.data;
      }
      if (
        [HttpStatusCodeEnum.UNAUTHORIZED, SystemStatusCodeEnum.ERROR_UNAUTHORIZED].includes(
          requestCode,
        )
      ) {
        message.info('您的登录已过期，请重新登录');
        removeToken();
        // 提示需要退出登录
        window.location.href = '/login';
      }

      if (requestCode !== SystemStatusCodeEnum.SUCCESS) {
        message.error(errorMessage);

        return Promise.reject();
      }
      const data = config.data ?? null;
      return Promise.resolve(data);
    }
  },
  (error: Error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const status = (error as AxiosError)?.response?.status;
    if (status) {
      message.error(t(`request.exception.${status}`));
      return Promise.reject();
    }
    return Promise.reject();
  },
);

export default http;
